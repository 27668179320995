var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-6"},[_c('v-sheet',{staticClass:"dense-inputs"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex flex-row float-right",attrs:{"cols":"12","lg":"12","md":"12","xs":"12"}},[_c('v-text-field',{staticClass:"mt-2 mb-0 force-text-left",attrs:{"label":_vm.$t('message.filterResults'),"value":_vm.searchTerm,"autocomplete":"off","prepend-inner-icon":"mdi-filter-outline"},on:{"change":function($event){_vm.searchTerm = $event}}}),_c('v-btn',{staticClass:"ml-3",attrs:{"loading":_vm.loading.filterResults},on:{"click":_vm.filterResults}},[_vm._v(_vm._s(_vm.$t('message.filter')))]),_c('v-btn',{staticClass:"ml-3",on:{"click":function($event){_vm.searchTerm = null}}},[_c('v-icon',[_vm._v("refresh")])],1),_c('ExportTable',{staticClass:"mt-0 mr-0",staticStyle:{"padding-top":"2px !important"},attrs:{"has-add-function":true,"cols-to-delete":_vm.colsToDelete,"file-name":"itemSpecs","table-id":"itemSpecsTable"},on:{"add-action":function($event){return _vm.openCreateItemSpecDialog()}}}),_c('HelpPopUpV2',{attrs:{"help-page-component":"ItemSpecsListing","no-padding":true}})],1)],1)],1),_c('div',{staticStyle:{"position":"relative"}},[_c('v-overlay',{attrs:{"value":_vm.loading.itemSpecs,"absolute":"","opacity":"0.15"}},[_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"40","width":"6"}})],1)],1)],1),_c('v-data-table',{staticClass:"mt-0 appic-table-light specification-table",attrs:{"footer-props":{
                itemsPerPageOptions: [20,50,-1],
                showCurrentPage: true,
                showFirstLastPage: true
            },"headers":_vm.headers,"height":_vm.tableHeight,"items":_vm.allCleanedSpecs,"items-per-page":-1,"search":_vm.searchTerm,"options":_vm.tableOptions,"calculate-widths":"","dense":"","fixed-header":"","id":"itemSpecTable","item-key":"Spec.id","sort-by":"Spec.version","sort-desc":""},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"text-center align-center"},[_vm._v(_vm._s(_vm.allCleanedSpecs.indexOf(item) + 1))])]}},{key:"item.Spec.title",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold align-center"},[_vm._v(_vm._s(item.Spec.title))])]}},{key:"item.Spec.mc_id",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"align-center"},[_vm._v(_vm._s(_vm.getMoistureContent(item.Spec.mc_id)))])]}},{key:"item.Spec.version",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"align-center"},[_vm._v(_vm._s(item.Spec.version))])]}},{key:"item.Spec.productgroups",fn:function(ref){
            var item = ref.item;
return _vm._l((item.Spec.productGroupNames),function(product,idx){return _c('v-chip',{key:product + '_' + idx,staticClass:"mr-1 mb-1",attrs:{"small":""}},[_vm._v(_vm._s(product))])})}},{key:"item.Spec.id",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var menu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"icon":""}},Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('message.moreActions')))])])]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{staticClass:"font-sm",on:{"click":function($event){return _vm.openEditItemSpecDialog(item.Spec.id, item.Spec.title)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"black lighten-1","small":""}},[_vm._v("edit")]),_vm._v(_vm._s(_vm.$t('message.updateItemSpec'))+" ")],1),_c('v-list-item',{staticClass:"font-sm",on:{"click":function($event){return _vm.deleteItemSpec(item.Spec.id, item.Spec.title)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"red lighten-1"}},[_vm._v("cancel")]),_vm._v(_vm._s(_vm.$t('message.deleteItemSpec')))],1)],1)],1)],1)]}}])})],1),_c('AddItemSpec',{attrs:{"item-spec-id":_vm.selectedItemSpecId,"item-spec-name":_vm.selectedItemSpecName,"dialog":_vm.editDialog,"page-key":_vm.pageKey,"update-mode":_vm.updateMode},on:{"update:dialog":function($event){_vm.editDialog=$event},"dialog-closed":_vm.editDialogClosed,"update-done":_vm.itemSpecUpdated,"create-done":_vm.itemSpecCreated}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }